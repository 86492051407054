.ergodex-form-page-wrapper {
  position: relative;
  animation: fadein 400ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
