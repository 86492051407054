@import "~@ergolabs/ui-kit/dist/styles/variables.light";
@import "~@ergolabs/ui-kit/dist/styles/variables.dark";

.system {
  @media (prefers-color-scheme: light) {
    .lightTheme();
    --spectrum-logo-color: var(--spectrum-primary-color);
    --spectrum-asset-box-border-color: #f0f0f0;
    --spectrum-ido-notifcation-background: #aba5fc;
  }

  @media (prefers-color-scheme: dark) {
    .darkTheme();
    --spectrum-logo-color: var(--spectrum-primary-text);
    --spectrum-asset-box-border-color: #303030;
    --spectrum-ido-notifcation-background: #37306d;
  }
}

