@keyframes cookie-policy-out-bottom {
  80% {
    transform: translate(-50%, -5%);
  }

  to {
    transform: translate(-50%, 110%);
  }
}

.ant-notification-notice.cookie-policy {
  position:   fixed;
  bottom:     8px;
  left:       50%;
  width:      calc(100% - 48px);
  max-width:  996px;
  min-height:     80px;
  padding:    1rem;
  border:     1px solid var(--spectrum-box-border-color);
  background: var(--spectrum-box-bg);
  border-radius: var(--spectrum-border-radius-l);
  box-shadow: none;
  transform: translateX(-50%);

  &.ant-notification-fade-leave {
    animation-duration: 0.3s !important;
    animation-name: cookie-policy-out-bottom !important;
  }

  .ant-notification-notice-message {
    padding-right: 0;
    margin: 0;
  }

  .ant-notification-notice-btn {
    display: none;
  }
}
